import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { UiLineSeparator } from '@uireact/separator';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "contributing"
    }}>{`Contributing`}</h1>
    <UiLineSeparator mdxType="UiLineSeparator" />
    <br />
    <iframe src="https://giphy.com/embed/3ohzgD1wRxpvpkDCSI" width="300" height="200" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
    <h2 {...{
      "id": "first-"
    }}>{`FIRST! 🧐`}</h2>
    <p>{`Before starting to work on a contribution let's create an `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/issues"
      }}>{`issue`}</a>{` in github so we can chat about what you are planning to build before you put your time into it.`}</p>
    <h2 {...{
      "id": "pull-request-"
    }}>{`Pull Request 🔥`}</h2>
    <p>{`Make sure you fill the PR template and it passes the prechecks:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/inavac182/uireact/actions/workflows/build.yml"
        }}>{`Build`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/inavac182/uireact/actions/workflows/test.js.yml"
        }}>{`Tests and Lint`}</a></li>
    </ul>
    <iframe src="https://giphy.com/embed/13HgwGsXF0aiGY" width="300" height="200" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
    <h2 {...{
      "id": "publish-"
    }}>{`Publish 🚀`}</h2>
    <p>{`The version upgrades are done automatically by lerna using `}<a parentName="p" {...{
        "href": "https://www.conventionalcommits.org/en/v1.0.0/#summary"
      }}>{`conventional commits`}</a>{` versioning.`}</p>
    <p>{`Once a PR is merged the `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/blob/main/.github/workflows/pipeline.yml"
      }}>{`pipeline gh action`}</a>{` a.k.a `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/actions/workflows/pipeline.yml"
      }}>{`publish and deploy`}</a>{` is triggerd, it will build the packages and publish to npm new versions of the changed packages. It will also deploy the `}<a parentName="p" {...{
        "href": "https://uireact.io/"
      }}>{`uireact docs`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      